import React, { ReactPortal, useEffect, useRef } from 'react';
import { useSideDrawerContext } from '@contexts/sideDrawerContext';
import { createPortal } from 'react-dom';
import CloseButton from '@common/side-drawer/components/close-button';
import { KEYBOARD_KEYS } from '@constants';
import './side-drawer.scss';
import { handleTabKey } from '@common/modal/modal';

const SideDrawer = (): ReactPortal => {
    const sideDrawerRef = useRef<HTMLDivElement>(null);
    const drawerContentRef = useRef<HTMLDivElement>(null);
    const closeIconRef = useRef<HTMLButtonElement>(null);
    const { sideDrawerContext, isVisible, resetContext } =
        useSideDrawerContext();

    const closeDrawer = (): void => {
        const sideDrawerBackdrop = document.getElementById(
            'side-drawer-backdrop'
        );
        const sideDrawerPortal = document.getElementById('side-drawer-portal');
        sideDrawerPortal?.classList.add('closed');
        sideDrawerBackdrop?.classList.remove('open');

        setTimeout(() => resetContext(), 325);
    };
    const setDefaultFocus = () => {
        closeIconRef?.current?.focus();
    };

    const handleEscapeKey = (event: KeyboardEvent | React.KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            closeDrawer();
        }
    };

    const keyDownHandler = (event: KeyboardEvent | React.KeyboardEvent) => {
        handleEscapeKey(event);
        if (event.key === KEYBOARD_KEYS.TAB) {
            handleTabKey(event, drawerContentRef.current);
        }
    };

    const onDocumentClicked = (event: MouseEvent) => {
        if (!sideDrawerRef?.current?.contains(event.target as Node)) {
            closeDrawer();
        }
    };

    useEffect(() => {
        const sideDrawerBackdrop: HTMLElement = document.getElementById(
            'side-drawer-backdrop'
        );

        if (sideDrawerContext.drawerType.name && drawerContentRef.current) {
            document.addEventListener('mousedown', onDocumentClicked, false);
            document.addEventListener('keydown', keyDownHandler, false);
            document.body.classList.add('open');
            sideDrawerBackdrop?.classList.add('open');
            setDefaultFocus();
        }

        return () => {
            document.removeEventListener('mousedown', onDocumentClicked, false);
            document.removeEventListener('keydown', keyDownHandler, false);
            document.body.classList.remove('open');
            sideDrawerBackdrop?.classList.remove('open');
        };
    }, [sideDrawerContext.drawerType.name, drawerContentRef]);

    useEffect(() => {
        if (closeIconRef?.current) setDefaultFocus();
    }, [closeIconRef]);

    if (!sideDrawerContext.drawerType.name) return null;

    return createPortal(
        <div
            ref={sideDrawerRef}
            data-testid="side-drawer-container"
            className={`side-drawer__container ${
                sideDrawerContext.drawerType.name
            } fmc-dialog ${isVisible ? 'open' : ''}`}
            role="dialog"
            aria-modal={true}
        >
            <div className="side-drawer__content" ref={drawerContentRef}>
                <CloseButton
                    onClick={closeDrawer}
                    ref={closeIconRef}
                    id={`${sideDrawerContext.drawerType.name}-close-button`}
                />
                {sideDrawerContext.drawerType.children}
            </div>
        </div>,
        document.getElementById('side-drawer-portal')
    );
};

export default SideDrawer;
