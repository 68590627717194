import React, { useEffect, useState } from 'react';

import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import ErrorPage from '@/components/sections/profile-error/error-page';
import './order-details-view.scss';
import AuthenticationService from '@/services/authentication-service/authentication-service';
import { CacheService } from '@/services/cache-service/cache-service';
import ProfileService from '@/services/profile-service/profile-service';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import OrderDetailsComponent from './components/order-details-component';
import { ProfileWithVehiclesResponse } from '@/models/profile-with-vehicles';
import OmsService, {
    OmsOrderDetailsResponse,
} from '@/services/oms-service/oms-service';
import HttpService from '@/services/http-service/http-service';
import { useOrderDetailsContent } from './hooks/use-order-details-content';
import { useOrderHistoryContent } from '../order-history-view/hooks/use-order-history-content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import { useOrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NotificationType,
    useNotificationContext,
} from '@/contexts/notificationContext';
import { Notification } from '@/components/sections/account-portal/components/notification-message/notification';
import { useAnalytics } from '@/hooks/use-analytics';

const className = 'order-details';

const OrderDetailsView = () => {
    useAnalytics('orderDetails');
    const navigate = useNavigate();
    const location = useLocation();
    const [showErrorPage, setShowErrorPage] = useState(false);
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const profileService = new ProfileService();
    const omsService = new OmsService(HttpService);
    const orderDetailsContent = useOrderDetailsContent();
    const orderHistoryContent = useOrderHistoryContent();
    const orderStatusContent = useOrderStatusContent();
    const appConfig = new AppConfigurationService();
    const [ordersFinishedLoading, setOrdersFinishedLoading] = useState(false);
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse>(null);
    const [orderDetailsData, setOrderDetailsData] =
        useState<OmsOrderDetailsResponse>(null);
    const orderDetailsDataFromState: OmsOrderDetailsResponse =
        location.state?.orderDetails;
    const { orderNo } = useParams<{ orderNo: string }>();
    const { setNotificationContext } = useNotificationContext();

    const generateOrderNumberAndPutInStorage = (): void => {
        const pathname = location.pathname;
        const splitPathname = pathname.split('/');

        if (splitPathname[splitPathname.length - 1].startsWith('FAE:')) {
            sessionStorage.setItem(
                'nvcOrderDetailsOrderNumber',
                splitPathname[splitPathname.length - 1]
            );
        }
    };

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        new ScrollUtil().scrollPageToTop();
    }, []);

    const checkAuthenticationStatus = () => {
        cacheService.evictProfileServiceCache();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService
                        .requestLite()
                        .then((profile) => {
                            if (profile) {
                                setProfileData(profile);
                            }
                        })
                        .catch(() => {
                            setShowErrorPage(true);
                            cacheService.evictProfileServiceCache();
                        });
                } else if (!orderDetailsDataFromState) {
                    generateOrderNumberAndPutInStorage();
                    navigate({
                        pathname: findPathByAlias('GuestOrderView'),
                        search: location.search,
                    });
                }
            });
    };

    useEffect(() => {
        const getOrderData = async () => {
            if (profileData?.profile?.email) {
                omsService
                    .getCompleteOrderDetails(
                        profileData?.profile?.email.toLocaleLowerCase(),
                        orderNo
                    )
                    .then((orderDetails) => {
                        setOrderDetailsData(orderDetails);
                    })
                    .catch(() => {
                        setShowErrorPage(true);
                    })
                    .finally(() => {
                        setOrdersFinishedLoading(true);
                    });
            }
        };

        if (orderDetailsDataFromState) {
            setOrderDetailsData(orderDetailsDataFromState);
            setOrdersFinishedLoading(true);
        } else {
            getOrderData();
        }
    }, [profileData, showErrorPage, orderDetailsDataFromState]);

    useEffect(() => {
        if (!orderDetailsData && ordersFinishedLoading) {
            setNotificationStatus(NotificationType.Error);
        }
    }, [ordersFinishedLoading]);

    useEffect(() => {
        checkAuthenticationStatus();
    }, []);

    const renderContent = () => {
        if (showErrorPage) {
            return <ErrorPage />;
        } else if (
            orderDetailsContent &&
            orderStatusContent &&
            (profileData || orderDetailsData)
        ) {
            return (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}-view`}
                >
                    {location.state?.guestOrder && (
                        <Breadcrumbs
                            currentPage={
                                orderDetailsContent.orderDetailsbreadcrumbTitle
                            }
                            previousPage={
                                orderDetailsContent.guestDetailsBreadcrumbTitle
                            }
                            previousLink={findPathByAlias('GuestOrderView')}
                            hideBaseBreadcrumb={true}
                            crumbSeparator={
                                appConfig.brand === 'ford' ? '/' : '>'
                            }
                        ></Breadcrumbs>
                    )}
                    {profileData && !location.state?.guestOrder && (
                        <Breadcrumbs
                            currentPage={
                                orderDetailsContent?.orderDetailsbreadcrumbTitle
                            }
                            previousPage={orderHistoryContent?.breadcrumbTitle}
                            previousLink={findPathByAlias('OrderHistoryView')}
                            crumbSeparator={
                                appConfig.brand === 'ford' ? '/' : '>'
                            }
                        ></Breadcrumbs>
                    )}
                    {profileData && notificationStatus && (
                        <div
                            className={`order-details-view-notification-container`}
                            data-testid={`order-details-view-notification-container`}
                        >
                            <Notification
                                status={notificationStatus}
                                mainCopy={
                                    orderDetailsContent.errorNotificationMessage
                                }
                                hideBorder={true}
                                hideAfterTimeout={false}
                                onHideNotification={() => {
                                    setNotificationStatus(null);
                                }}
                            />
                        </div>
                    )}
                    <OrderDetailsComponent
                        orderDetailsContent={orderDetailsContent}
                        orderDetailsData={orderDetailsData}
                        orderStatusContent={orderStatusContent}
                        ordersFinishedLoading={ordersFinishedLoading}
                        authenticated={profileData}
                    />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            );
        } else {
            return <ActivityIndicator className={'full-height'} />;
        }
    };

    return <>{renderContent()}</>;
};

export default OrderDetailsView;
