import React from 'react';
import { useAnalytics } from '@hooks/use-analytics';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { HelpCardsProps } from '@views/page-not-found-view/hooks/use-help-cards-content';

const contactUsIcon = './icons/phone-icon.svg';
const scheduleServiceIcon = './icons/auto-repair-icon.svg';
const findDealerIcon = './icons/find-dealer-icon.svg';

interface Props {
    helpCardsContent: HelpCardsProps;
}

export const SelfHelpButtons = (props: Props) => {
    const [fireAnalytics] = useAnalytics();
    return (
        !props.helpCardsContent?.hide && (
            <div className="self-help-buttons-container">
                <div className="self-help-buttons-wrapper">
                    <SecondaryButton
                        className="contact-button"
                        onClick={() =>
                            fireAnalytics([
                                'contactUsOnclickEvent',
                                'contactUsReferralExit',
                            ])
                        }
                        aria-label={
                            props.helpCardsContent?.contactUsHelpCardAriaLabel
                        }
                        link={props.helpCardsContent?.contactUsHelpCardURL}
                        linkTarget={
                            props.helpCardsContent?.contactUsHelpCardTarget
                        }
                        data-testid="self-help-contact-button"
                    >
                        {props.helpCardsContent?.contactUsHelpCardTitle}
                        <span>
                            <img
                                className="contact-us-icon"
                                src={contactUsIcon}
                                alt=""
                            />
                        </span>
                    </SecondaryButton>
                    <SecondaryButton
                        className="schedule-service-button"
                        onClick={() =>
                            fireAnalytics([
                                'findVehicleOnclickEvent',
                                'findVehicleReferralExit',
                            ])
                        }
                        aria-label={
                            props.helpCardsContent
                                ?.myFordVehicleHelpCardAriaLabel
                        }
                        link={props.helpCardsContent?.myFordVehicleHelpCardURL}
                        linkTarget={
                            props.helpCardsContent?.myFordVehicleHelpCardTarget
                        }
                        data-testid="self-help-schedule-service-button"
                    >
                        {props.helpCardsContent?.myFordVehicleHelpCardTitle}
                        <span>
                            <img
                                className="schedule-service-icon"
                                src={scheduleServiceIcon}
                                alt=""
                            />
                        </span>
                    </SecondaryButton>
                    <SecondaryButton
                        className="find-dealer-button"
                        data-testid="self-help-find-dealer-button"
                        aria-label={
                            props.helpCardsContent?.findVehicleHelpCardAriaLabel
                        }
                        link={props.helpCardsContent?.findVehicleHelpCardURL}
                        onClick={() =>
                            fireAnalytics([
                                'findVehicleOnclickEvent',
                                'findVehicleReferralExit',
                            ])
                        }
                    >
                        {props.helpCardsContent?.findVehicleHelpCardTitle}
                        <span>
                            <img
                                className="find-dealer-icon"
                                src={findDealerIcon}
                                alt=""
                            />
                        </span>
                    </SecondaryButton>
                </div>
            </div>
        )
    );
};
