import React, { useMemo } from 'react';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { useVehicleImage } from '@sections/account-portal/hooks/use-vehicle-image';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { ORDER_STATUS_CODES } from '@constants';
import { chooseOrderStatusClass } from '@views/order-history-view/components/order-history-drawer/hooks/order-status-util';

import './order-history-vehicle-item.scss';

export interface VehicleItemProps {
    order: OrderedVehiclesItem;
    vehicleCarouselContent: VehicleCarouselContent;
    current3LetterCountryCapitalizedCode: string;
    appConfig: any;
}

const OrderHistoryVehicleItem = (props: VehicleItemProps) => {
    const {
        order,
        vehicleCarouselContent,
        current3LetterCountryCapitalizedCode,
        appConfig,
    } = props;

    const imageUrl: string = order?.imageUrl || null;
    const vinForVehicleImageLookup: VehicleAttributes = {
        vin: order?.vin,
        year: Number(order.year),
        make: order.make,
        model: order.model,
    };
    const vehicleImage = useVehicleImage(
        vinForVehicleImageLookup,
        imageUrl,
        'undefined',
        current3LetterCountryCapitalizedCode
    );
    const finalImage = useMemo<string>(() => vehicleImage, [vehicleImage]);
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const marketStatusCodes = ORDER_STATUS_CODES[isEu ? 'eu' : 'us'];
    const [statusBackgroundColor, orderStatusAlertText] =
        chooseOrderStatusClass(
            order,
            order?.vehicleStatusCode || order?.status,
            vehicleCarouselContent,
            marketStatusCodes
        );

    const convertTimeStampToFormattedDate = (timestamp: string): string => {
        const date = new Date(timestamp);
        const languageRegionCode = appConfig
            .getLanguageRegionCode()
            .toLowerCase();
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        };
        return date.toLocaleDateString(languageRegionCode, options);
    };

    return (
        <div
            className="order-history-vehicle-item__container"
            data-testid="order-history-vehicle-item"
        >
            <div className="order-history-vehicle-item__image">
                {finalImage && (
                    <img
                        src={finalImage}
                        alt={order.model}
                        crossOrigin="anonymous"
                    />
                )}
            </div>
            <div className="order-history-vehicle-item__content">
                <p className="order-date__label">
                    {vehicleCarouselContent?.orderDateLabelText}:
                </p>
                <p className="order-date__value">
                    {convertTimeStampToFormattedDate(order.purchaseDate)}
                </p>

                {order.status && (
                    <p
                        className="order-status"
                        style={{ backgroundColor: statusBackgroundColor }}
                    >
                        {orderStatusAlertText}
                    </p>
                )}

                <p className="ymm">
                    {order.year} {order.model}
                </p>
                <p className="order-number">#{order.id}</p>
            </div>
        </div>
    );
};

export default OrderHistoryVehicleItem;
