import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState,
    useCallback,
    Dispatch,
    SetStateAction,
} from 'react';
import './add-vehicle.scss';
import ProfileService from '@services/profile-service/profile-service';
import PrimaryButton from '@common/primary-button/primary-button';
import { useLocation } from 'react-router-dom';
import { CacheService } from '@services/cache-service/cache-service';
import { useAnalytics } from '@hooks/use-analytics';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useErrorsContent } from '@views/page-not-found-view/hooks/use-errors-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { NA_COUNTRIES, MAX_VEHICLES_LIMIT } from '@constants';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import AddVehicleMaxGarageLimitContent from './components/add-vehicle-max-garage-limit-content/add-vehicle-max-garage-limit-content';
import AddVehicleErrorContent from './components/add-vehicle-error-content/add-vehicle-error-content';
import AddVehicleModalContent from './components/add-vehicle-modal-content/add-vehicle-modal-content';
import { AddVehicleContent } from './hooks/use-add-vehicle';
import { useVinValidation } from './hooks/use-vin-validation';
import AddVehicleVinMismatchContent from '@sections/add-vehicle/components/add-vehicle-vin-mismatch-content/add-vehicle-vin-mismatch-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { SecondaryButton } from '@/components/common';

const plusSignIcon = './icons/plus-sign-icon.svg';
const plusSignIconBlue = './icons/plus-sign-blue.svg';
interface AddVehicleProps {
    displayAddButton: boolean;
    addVehicleContent: AddVehicleContent;
    ctaType?: string;
    hasDynamicMasthead?: boolean;
    buttonClass?: string;
    buttonText?: string;
    currentVehiclesCount?: number;
    setCurrentVehiclesCount?: Dispatch<SetStateAction<number>>;
    className?: string;
    addButtonPlusSign?: boolean;
    updateAddVehicleStatusWithVin?: (vin: string) => void;
    updateVehiclesData?: (cachable: boolean, flag?: boolean) => void;
    validateWFOVIN?: string;
    isEURegion?: boolean;
    setFocusToAddButton?: boolean;
    getStartedAriaLabel?: string;
    setIsLoading?: any;
    isVisible?: boolean;
    fromOwnerAddVehicle?: boolean;
    fromConnectedServices?: boolean;
    fromSmartTile?: boolean;
    fromCard?: boolean;
    openAddVehicleModal?: boolean;
    onOrderStatusVin?: string[];
    vehiclesOnOrder: OrderedVehiclesItem[];
    setIsNewVehicleAdded?: Dispatch<SetStateAction<boolean>>;
    garageVehicles: VehicleAttributes[];
}

export const vinSanitizer = (vinInputValue) => {
    return vinInputValue
        .match(/[A-Za-z0-9]/gu)
        .toString()
        .split(',')
        .join('')
        .toUpperCase();
};

const AddVehicleWrapper = (props: AddVehicleProps, ref: any) => {
    const location = useLocation();
    const { setContext, resetContext } = useModalContext();
    const [fireAnalytics] = useAnalytics();
    const {
        vinErrorState,
        vinErrorMessage,
        validateVINOrReg,
        isValidForEu,
        isValidForNonEu,
        resetVinErrorState,
    } = useVinValidation(props.addVehicleContent, props.isEURegion);
    const [isOnOrderStatusVin, setIsOnOrderStatusVin] =
        useState<boolean>(false);
    const [vinAlreadyInGarage, setVinAlreadyInGarage] =
        useState<boolean>(false);
    const errorsContent = useErrorsContent();
    const queryParams = new URLSearchParams(location.search);
    const appConfig = new AppConfigurationService();
    const profileService = new ProfileService();
    const [addVehicleModal, errorModal, vinMismatchModal] = [
        'add-vehicle-modal',
        'error-modal',
        'vin-mismatch-modal',
    ];
    const isLincoln = appConfig.brand === 'lincoln';
    const vinRef = useRef(null);
    const nickRef = useRef(null);
    const tooltipRef = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formValues, setFormValues] = useState({ vin: null, nickName: null });
    const [showTooltip, toggleTooltip] = useState<boolean>(null);
    const [updateClickedWithEmptyForm, setUpdateClickedWithEmptyForm] =
        useState<boolean>(false);
    const [errorResponse, setErrorResponse] = useState<any>();
    const [modalCloseState, handleModalCloseState] = useState(false);

    const isNa = NA_COUNTRIES.includes(appConfig.get2LetterCountryCode());

    const formInput = (): [HTMLInputElement, HTMLInputElement] => {
        const vinInput = vinRef.current as HTMLInputElement;
        const nicknameInput = nickRef.current as HTMLInputElement;
        return [vinInput, nicknameInput];
    };

    const toggleUpdateButtonState = (state: string) => {
        const updateButton = document.querySelector(
            '.modal-footer .fmc-button'
        ) as HTMLButtonElement;
        if (updateButton) {
            if (state === 'disabled') {
                updateButton.disabled = true;
                updateButton.classList.add('disabled');
            } else {
                updateButton.disabled = false;
                updateButton.classList.remove('disabled');
            }
        }
    };

    const openAddVehicleModal = useCallback(() => {
        if (props.currentVehiclesCount >= MAX_VEHICLES_LIMIT) {
            setContext(maxGarageLimitProps);
        } else {
            setContext(addVehicleModalProps);
            toggleTooltip(null);
        }

        sessionStorage.removeItem('addedVehicleVin');
        toggleUpdateButtonState('active');
    }, [props.currentVehiclesCount]);

    const handleUpdateVehicle = async () => {
        // avoid open the connected service modal on re-renter
        if (queryParams.has('connectedservices')) {
            props.updateVehiclesData && props.updateVehiclesData(false, true);
        } else props.updateVehiclesData && props.updateVehiclesData(false);
    };

    function fireAnalyticsForAddVehicleResponse(response) {
        let shortCodeMessage = 'No response message found.';

        if (response.status === 200) {
            shortCodeMessage = 'Vehicle Added Successfully';
        }
        if (
            (response.status >= 400 && response.status <= 599) ||
            (response.httpStatus >= 400 && response.httpStatus <= 599)
        ) {
            if (response.error?.message) {
                shortCodeMessage = response.error.message;
            } else if (response.data?.message) {
                shortCodeMessage = response.data.message;
            } else if (response.data?.error?.message) {
                shortCodeMessage = response.data.error.message;
            } else {
                shortCodeMessage = 'No error response message found.';
            }
        }

        // filters the VIN out of the response message
        const vinFilteringRegex = new RegExp(/(VIN)(\s*\(.*\))(.+)/, 'i');
        shortCodeMessage = shortCodeMessage.replace(vinFilteringRegex, '$1$3');

        if (response.status === 500 || response.httpStatus === 500) {
            shortCodeMessage =
                'Failed with 500/server error: ' + shortCodeMessage;
        }

        fireAnalytics('addVehicleStatusOnclickEvent', null, {
            addVehicleStatus: shortCodeMessage,
        });
    }

    function checkIfVinIsMismatched(vin: string) {
        const vinBrandCharacter = vin.charAt(1).toLowerCase();
        const isVinMismatched =
            (isNa && !isLincoln && vinBrandCharacter === 'l') ||
            (isLincoln && vinBrandCharacter === 'f');

        return Boolean(
            isVinMismatched && props.addVehicleContent?.vinMismatchConfirmLink
        );
    }

    const isAlreadyInOrderCardVins = (vin: string): boolean => {
        const ordersVinArray: string[] = props.vehiclesOnOrder.map(
            (order) => order.vin
        );

        return ordersVinArray.includes(vin);
    };

    const isAlreadyInGarageVins = (vin: string): boolean => {
        const garageVinArray: string[] = props.garageVehicles.map(
            (vehicle) => vehicle.vin
        );

        return garageVinArray.includes(vin);
    };

    const addVehicle = async (vin: string, nickname: string) => {
        if (
            vin &&
            !isAlreadyInOrderCardVins(vin) &&
            !isAlreadyInGarageVins(vin)
        ) {
            setIsLoading(true);
            resetContext();
            setErrorResponse(null);
            setIsOnOrderStatusVin(false);
            setVinAlreadyInGarage(false);
            props.setIsLoading(true);
            profileService
                .addVehicle(vin, nickname)
                .then(async (response) => {
                    fireAnalyticsForAddVehicleResponse(response);

                    if (response.status === 200 && !response.error) {
                        new CacheService().evictProfileServiceCache();
                        props.setIsNewVehicleAdded(true);

                        // Update newly added vehicle VIN number to parent component. This is for updating Dashboard carousel cards and redirecting to Connected Services page with VIN number
                        // Note: This will execute only the user adding a vehicle from the Connected Services tile.

                        props.updateAddVehicleStatusWithVin &&
                            props.updateAddVehicleStatusWithVin(vin);

                        sessionStorage.setItem('addedVehicleVin', vin);
                        setFormValues({ vin: null, nickName: null });
                        toggleTooltip(null);
                        resetContext();
                        await handleUpdateVehicle();
                        profileService.request().then((profileData) => {
                            props.setCurrentVehiclesCount(
                                profileData['vehicles']?.length
                            );
                        });
                        if (checkIfVinIsMismatched(vin)) {
                            const vinMismatchProps =
                                createVinMismatchProps(false);
                            setContext(vinMismatchProps);
                        }
                    } else {
                        setErrorResponse(response);
                    }
                })
                .catch((err: any) => {
                    setErrorResponse(err.response);
                })
                .finally(() => {
                    props.setIsLoading(false);
                    setIsLoading(false);
                });
        }
    };

    const addVehicleAndClearErrors = (
        vinInput: HTMLInputElement,
        nicknameInput: HTMLInputElement
    ) => {
        const isNicknamePopulated =
            nicknameInput !== null && nicknameInput.value;
        resetVinErrorState();
        addVehicle(
            vinInput.value.replace(/-|\s/g, null).toUpperCase(),
            isNicknamePopulated ? nicknameInput.value : ''
        );
        resetContext();
    };

    const reEnter = () => {
        fireAnalytics('reenterIncorrectVinOnclickEvent');
        setFormValues({ vin: null, nickName: null });
        setErrorResponse(null);
        isOnOrderStatusVin && setIsOnOrderStatusVin(false);
        vinAlreadyInGarage && setVinAlreadyInGarage(false);
        openAddVehicleModal();
    };

    const navigateToOppositeSite = () => {
        window.open(props.addVehicleContent.vinMismatchConfirmLink);
    };

    const focusNextElement = () => {
        document.getElementById('add-vehicle-btn')?.focus();
    };

    const handleVinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const vin = event.target?.value;
        setFormValues((prevState) => {
            return {
                ...prevState,
                vin,
            };
        });
        validateVINOrReg(vin);
    };

    const handleNicknameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                nickName: event.target.value
                    .replace(/[$&+,:;=?@#|'<>/\\.^*()%!-]/g, '')
                    .trimStart(),
            };
        });
    };

    const handleFocus = () => {
        handleModalCloseState(true);
    };

    const handleUpdate = () => {
        const [vinInput, nicknameInput] = formInput();

        if (!vinInput.value || (!vinInput.value && !nicknameInput.value)) {
            setUpdateClickedWithEmptyForm(true);
        }

        isAlreadyInOrderCardVins(vinInput.value) && setIsOnOrderStatusVin(true);
        isAlreadyInGarageVins(vinInput.value) && setVinAlreadyInGarage(true);

        fireAnalytics(['updateButtonAddVehicleOnclickEvent']);

        const nextFormValues = {
            vin: vinInput.value === '' ? null : vinInput.value,
            nickName: nicknameInput
                ? nicknameInput.value === ''
                    ? null
                    : nicknameInput.value
                : null,
        };

        setFormValues(nextFormValues);
        toggleTooltip(null);
        if (!isNa && vinInput.value.charAt(1).toLowerCase() === 'l') {
            const response = {
                status: 404,
                data: {
                    requestStatus: 'UNAVAILABLE',
                },
            };
            setErrorResponse(response);
        } else if (props.isEURegion) {
            if (isValidForEu(vinInput.value)) {
                addVehicle(
                    vinInput.value.replace(/-|\s/g, null).toUpperCase(),
                    nicknameInput?.value
                );
            }
        } else {
            // Validations for Non-EU regions
            if (isValidForNonEu(vinInput.value)) {
                addVehicleAndClearErrors(vinInput, nicknameInput);
            }
        }
    };

    const handleCloseOrCancel = (eventName?: string) => {
        if (eventName) {
            fireAnalytics([eventName]);
        }
        setFormValues({ vin: null, nickName: null });
        toggleTooltip(null);
        resetVinErrorState();
        setErrorResponse(null);
        setIsOnOrderStatusVin(false);
        setVinAlreadyInGarage(false);
        resetContext();

        if (props.fromConnectedServices) {
            window.history.replaceState(
                null,
                '',
                window.location.href.replace('?connectedservices=true', '')
            );
        }
        if (eventName && eventName === 'cancelButtonAddVehicleOnclickEvent') {
            props.currentVehiclesCount === 0 && handleFocus();
        }
    };

    const addVehicleModalProps: ModalContextProps = {
        modalType: {
            name: addVehicleModal,
            'aria-label': 'Add a Vehicle',
            primaryButtonLabel: props.addVehicleContent.btnUpdate,
            secondaryButtonLabel: props.addVehicleContent.btnCancel,
            onPrimaryButtonClick: handleUpdate,
            onSecondaryButtonClick: () =>
                handleCloseOrCancel('cancelButtonAddVehicleOnclickEvent'),
            onAfterClose: () =>
                handleCloseOrCancel('closeButtonAddVehicleOnclickEvent'),
            children: (
                <AddVehicleModalContent
                    addVehicleContent={props.addVehicleContent}
                    vinRef={vinRef}
                    nickRef={nickRef}
                    tooltipRef={tooltipRef}
                    formValues={formValues}
                    errorState={vinErrorState}
                    errorMessage={vinErrorMessage}
                    handleVinChange={handleVinChange}
                    handleNicknameChange={handleNicknameChange}
                    showTooltip={showTooltip}
                    toggleTooltip={toggleTooltip}
                />
            ),
        },
    };

    const errorModalProps: ModalContextProps = {
        modalType: {
            name: errorModal,
            primaryButtonLabel: props.addVehicleContent.reEnter,
            secondaryButtonLabel: props.addVehicleContent.btnCancel,
            onPrimaryButtonClick: reEnter,
            onSecondaryButtonClick: () => {
                handleCloseOrCancel('cancelIncorrectVinOnclickEvent');
            },
            onAfterClose: () => {
                handleCloseOrCancel();
                handleFocus();
            },
            children: (
                <AddVehicleErrorContent
                    errorsContent={errorsContent}
                    errorResponse={errorResponse}
                    addVehicleContent={props.addVehicleContent}
                    vinAlreadyExists={vinAlreadyInGarage || isOnOrderStatusVin}
                    isLincoln={isLincoln}
                />
            ),
        },
    };

    const maxGarageLimitProps: ModalContextProps = {
        modalType: {
            name: addVehicleModal,
            className: 'max-vehicles-error-modal',
            onAfterClose: () => {
                handleCloseOrCancel('closeButtonAddVehicleOnclickEvent');
                handleFocus();
            },
            primaryButtonLabel:
                props.addVehicleContent.addVehicleMaxLimitConfirmButton,
            onPrimaryButtonClick: () => {
                handleCloseOrCancel('closeButtonAddVehicleOnclickEvent');
            },
            children: (
                <AddVehicleMaxGarageLimitContent
                    addVehicleContent={props.addVehicleContent}
                    isLincoln={isLincoln}
                />
            ),
        },
    };

    const createVinMismatchProps = (vinAlreadyExists: boolean) => {
        return {
            modalType: {
                name: vinMismatchModal,
                primaryButtonLabel:
                    props.addVehicleContent.vinMismatchConfirmButton,
                secondaryButtonLabel:
                    props.addVehicleContent.vinMismatchCancelButton,
                onPrimaryButtonClick: () => {
                    navigateToOppositeSite();
                    handleCloseOrCancel();
                },
                onSecondaryButtonClick: () => {
                    handleCloseOrCancel();
                },
                onAfterClose: () => {
                    handleCloseOrCancel();
                    handleFocus();
                },
                children: (
                    <AddVehicleVinMismatchContent
                        vinMismatchContent={
                            vinAlreadyExists
                                ? props.addVehicleContent
                                      .mismatchVinAlreadyExistsMessage
                                : props.addVehicleContent.vinMismatchMessage
                        }
                    />
                ),
            },
        };
    };

    useEffect(() => {
        if (!props.displayAddButton) {
            openAddVehicleModal();
        }
    }, []);

    // When the user delete the last vehicle add vehicle button has to be focus
    useEffect(() => {
        if (props.setFocusToAddButton) {
            document.getElementById('add-vehicle-btn')?.focus();
        }
    }, [props.setFocusToAddButton, document.getElementById('add-vehicle-btn')]);

    useEffect(() => {
        if (modalCloseState === true) {
            handleModalCloseState(false);
            focusNextElement();
        }
    }, [modalCloseState]);

    useEffect(() => {
        if (
            !isLoading &&
            (formValues.vin !== null ||
                formValues.nickName !== null ||
                showTooltip !== null) &&
            !isOnOrderStatusVin
        ) {
            setContext(addVehicleModalProps);
        } else if (updateClickedWithEmptyForm) {
            setContext(addVehicleModalProps);
            setUpdateClickedWithEmptyForm(false);
        }
    }, [formValues, showTooltip, updateClickedWithEmptyForm]);

    useEffect(() => {
        if (errorResponse || vinAlreadyInGarage || isOnOrderStatusVin) {
            if (
                errorResponse &&
                errorResponse.httpStatus === 400 &&
                errorResponse.status === 727
            ) {
                const vinMismatchProps = createVinMismatchProps(true);
                setContext(vinMismatchProps);
            } else {
                setContext(errorModalProps);
            }
        }
    }, [errorResponse, vinAlreadyInGarage, isOnOrderStatusVin]);

    useImperativeHandle(
        ref,
        () => ({
            setFromOutside() {
                openAddVehicleModal();
            },
        }),
        []
    );

    const isFord = appConfig.brand == 'ford';

    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            {props.addVehicleContent && (
                <div
                    className={`add-vehicle-container add-vehicle-container-hidden fds-flex--center fmc-mt-4-xs fmc-mb-3-xs`}
                >
                    {props.displayAddButton ? (
                        <>
                            {props.ctaType === 'button' && !isFord && (
                                <PrimaryButton
                                    className={
                                        isLincoln || props.hasDynamicMasthead
                                            ? 'fmc-button'
                                            : `fmc-button--outlined`
                                    }
                                    onClick={() => {
                                        fireAnalytics('addVehicleOnclickEvent');
                                        openAddVehicleModal();
                                    }}
                                    id="add-vehicle-masthead-cta"
                                    testId="add-vehicle-masthead-cta"
                                    theme="dark"
                                >
                                    {props.buttonText}
                                </PrimaryButton>
                            )}
                            {props.ctaType === 'button' && isFord && (
                                <SecondaryButton
                                    className="add-vehicle-button fmc-button--no-shadow"
                                    onClick={() => {
                                        fireAnalytics('addVehicleOnclickEvent');
                                        openAddVehicleModal();
                                    }}
                                    id="add-vehicle-masthead-cta"
                                    dataTestId="add-vehicle-masthead-cta"
                                >
                                    {props.buttonText}
                                    <span className="add-vehicle-button-icon">
                                        <img
                                            className="plus-sign-icon"
                                            src={plusSignIconBlue}
                                            alt=""
                                        />
                                    </span>
                                </SecondaryButton>
                            )}
                            {props.ctaType !== 'button' && (
                                <div
                                    className="secondary-button"
                                    data-testid="add-vehicle-secondary-cta"
                                >
                                    <button
                                        className="fmc-text-button fmc-text-button--chevron-right"
                                        aria-label={props.getStartedAriaLabel}
                                        id="add-vehicle-btn_link"
                                        data-testid="add-vehicle-btn_link"
                                        onClick={() => {
                                            if (
                                                props.buttonText ===
                                                'Get Started'
                                            ) {
                                                fireAnalytics(
                                                    'getStartedOnclickEvent'
                                                );
                                            } else
                                                fireAnalytics(
                                                    'addVehicleOnclickEvent'
                                                );
                                            openAddVehicleModal();
                                        }}
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === ' ' ||
                                                e.key === 'Enter'
                                            ) {
                                                e.preventDefault();
                                                openAddVehicleModal();
                                            }
                                        }}
                                    >
                                        {props.buttonText}
                                    </button>
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
            )}
        </>
    );
};

export const AddVehicle = forwardRef(AddVehicleWrapper);
