import { useContent } from '@hooks/use-server-content';

export interface HelpRouterContentFragment {
    serviceHistoryText: string;
    serviceHistoryLink: string;
    serviceHistoryAriaLabel: string;
    serviceHistoryIcon: string;
}

export const useHelpRouterContentFragment =
    (): HelpRouterContentFragment | null => {
        const [content, getValueByName] = useContent('common', 'help-router');

        const contentFragment: any = {};
        if (content) {
            content?.elements?.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        if (!Object.keys(contentFragment).length) return null;

        return contentFragment as HelpRouterContentFragment;
    };
