import { useContent } from '@hooks/use-server-content';

export interface DealerConsentContent {
    modalHeaderText: string;
    modalBodyText: string;
    vehicleText: string;
    acceptButtonLabelText: string;
    acceptButtonAriaLabel: string;
    declineButtonLabelText: string;
    declineButtonAriaLabel: string;
    notificationBannerHeaderText: string;
    notificationBannerBodyText: string;
}

export const useDealerConsentContent = (): DealerConsentContent | null => {
    const [content, getValueByName] = useContent('common', 'dealer-consent');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as DealerConsentContent;
};
