import { useEffect, useState } from 'react';

import { VehicleAttributes } from '@models/vehicle-attributes';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import VehicleImageService from '../services/vehicle-image-service/vehicle-image-service';
import { DSL_VEHICLE_IMAGE_COUNTRIES } from '@/constants';

export const useVehicleImage = (
    vehicleAttributes: VehicleAttributes | OrderedVehiclesItem,
    imageUrl: string,
    page = 'undefined',
    countryCode: string
): string | null => {
    const [vehicleImage, setVehicleImage] = useState<string | null>(null);

    useEffect(() => {
        if (imageUrl) {
            setVehicleImage(imageUrl);
        } else {
            if (DSL_VEHICLE_IMAGE_COUNTRIES.includes(countryCode)) {
                new VehicleImageService()
                    .getVehicleImage(vehicleAttributes, page, countryCode)
                    .then((vehicleImageUrl) => {
                        setVehicleImage(vehicleImageUrl);
                    });
            } else {
                new VehicleImageService()
                    .getVehicleImageGpasStatic(vehicleAttributes)
                    .then((vehicleImageGpasUrl) => {
                        setVehicleImage(vehicleImageGpasUrl);
                    });
            }
        }
    }, []);
    return vehicleImage;
};
