import React from 'react';
import SmartTile, { SmartTileProps } from '../smart-tile/smart-tile';
import SmartTileIcon from '../smart-tile-icon/smart-tile-icon';
import './smart-tile-ford-credit.scss';

interface SmartTileFordCreditProps {
    iconPath: string;
    staticSubCopy: string;
    dynamicCTATitle: string;
    dynamicTitle: string;
    analyticEventName: string;
    isMobile: boolean;
}

const SmartTileFordCredit = (
    props: SmartTileFordCreditProps & SmartTileProps
) => {
    const {
        dynamicTitle,
        dynamicCTATitle,
        analyticEventName,
        isMobile,
        iconPath,
    } = props;

    const balanceDue = false;

    if (balanceDue) {
        return (
            <SmartTile
                {...props}
                className="smart-tile-ford-credit"
                header={dynamicTitle}
                ctaTitle={dynamicCTATitle}
                analyticEventName={analyticEventName}
                isMobile={isMobile}
            >
                <img
                    className="icon"
                    alt=""
                    src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
                />

                <h3 className={`fmc-type--subtitle1`}>
                    {dynamicTitle ? dynamicTitle : ''}
                </h3>

                <h3 className={`fmc-type--heading6`}>
                    {dynamicTitle ? dynamicTitle : ''}
                </h3>
            </SmartTile>
        );
    } else {
        return (
            <SmartTileIcon {...props} staticCTATitle={props.staticCTATitle} />
        );
    }
};

export default SmartTileFordCredit;
