import { KEYS, TAB_TIMEOUT } from './emplifi-wrapper';

function generateId() {
    return `hash_${Date.now()}`;
}

function getTTLDate() {
    const now = new Date();

    return new Date(now.getTime() + TAB_TIMEOUT);
}

function getRegistry(keyName: string) {
    return JSON.parse(<string>localStorage.getItem(keyName));
}

function saveRegistry(
    keyName: string,
    registryData: { tab: any; TTL: Date }[]
) {
    localStorage.setItem(keyName, JSON.stringify(registryData));
}

function isInRegistry(registryData: any[], item: any) {
    return registryData.some((el) => el.tab === item);
}

function saveTab(newItem: any) {
    if (localStorage.getItem(KEYS.TAB_REGISTRY)) {
        const registryData = getRegistry(KEYS.TAB_REGISTRY);
        const tabInRegistry = isInRegistry(registryData, newItem);

        if (!tabInRegistry) {
            registryData.push({
                tab: newItem,
                TTL: getTTLDate(),
            });
            saveRegistry(KEYS.TAB_REGISTRY, registryData);
        }
    } else {
        const registryData = [
            {
                tab: newItem,
                TTL: getTTLDate(),
            },
        ];

        saveRegistry(KEYS.TAB_REGISTRY, registryData);
    }
}

function removeTab(oldItem: any) {
    if (localStorage.getItem(KEYS.TAB_REGISTRY)) {
        const registryData = getRegistry(KEYS.TAB_REGISTRY);
        const tabInRegistry = isInRegistry(registryData, oldItem);

        if (tabInRegistry) {
            const newRegistryData = registryData.filter(
                (el: { tab: any }) => el.tab !== oldItem
            );

            saveRegistry(KEYS.TAB_REGISTRY, newRegistryData);
        }
    }
}

function update(item: any) {
    if (localStorage.getItem(KEYS.TAB_REGISTRY)) {
        const registryData = getRegistry(KEYS.TAB_REGISTRY);
        const tabInRegistry = isInRegistry(registryData, item);

        if (tabInRegistry) {
            const tabIndex = registryData.findIndex(
                (el: { tab: any }) => el.tab === item
            );

            registryData[tabIndex].TTL = getTTLDate();
            saveRegistry(KEYS.TAB_REGISTRY, registryData);
        }
    }
}

function removeOldTabs() {
    if (localStorage.getItem(KEYS.TAB_REGISTRY) != null) {
        const registryData = getRegistry(KEYS.TAB_REGISTRY);
        const now = Date.now();
        const newRegistryData = registryData.filter(
            (el: { TTL: string }) => Date.parse(el.TTL) >= now
        );

        if (newRegistryData.length > 0) {
            saveRegistry(KEYS.TAB_REGISTRY, newRegistryData);
        } else {
            localStorage.removeItem(KEYS.TAB_REGISTRY);
        }
    }
}

export default {
    generateId,
    saveTab,
    removeTab,
    update,
    removeOldTabs,
};
