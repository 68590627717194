import { ORDER_TYPE } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';

export const chooseOrderStatusClass = (
    order: OrderedVehiclesItem,
    orderStatus: string,
    vehicleCarouselContent: VehicleCarouselContent,
    marketStatusCodes: any
) => {
    let statusBackgroundColor: string, orderStatusAlertText: string;
    const gray = '#e6e8ea';
    const green = '#e5f3e6';

    if (!orderStatus) return;

    const orderStatusClasses = {
        ordered: {
            matched:
                order.orderType?.toUpperCase() === ORDER_TYPE.ORDER &&
                (!order.vehicleStatusCode || !order.vin) &&
                !order.readyToOrder,
            alertText: vehicleCarouselContent?.eComVehicleCardStatusOrdered,
        },
        reservation: {
            matched:
                order.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                !order.readyToOrder,
            alertText: vehicleCarouselContent?.eComVehicleCardStatusReservation,
        },
        placeOrder: {
            matched:
                order.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                order.readyToOrder,
            alertText: vehicleCarouselContent?.eComVehicleCardStatusPlaceOrder,
        },
        inProduction: {
            matched: marketStatusCodes?.inProduction?.includes(orderStatus),
            alertText:
                vehicleCarouselContent?.eComVehicleCardStatusInProduction,
        },
        shipped: {
            matched: marketStatusCodes?.shipped?.includes(orderStatus),
            alertText: vehicleCarouselContent?.eComVehicleCardStatusShipped,
        },
        purchaseCompleted: {
            matched:
                marketStatusCodes?.purchaseCompleted?.includes(orderStatus),
            alertText: vehicleCarouselContent?.eComVehicleCardCompletedPurchase,
        },
        delivered: {
            matched: marketStatusCodes?.delivered?.includes(orderStatus),
            alertText: vehicleCarouselContent?.eComVehicleCardDelivered,
        },
    };

    for (const key in orderStatusClasses) {
        const currentStatusClass: { matched: boolean; alertText: string } =
            orderStatusClasses[key];

        if (currentStatusClass.matched) {
            if (['inProduction', 'shipped', 'purchaseCompleted'].includes(key))
                statusBackgroundColor = green;

            if (['reservation', 'ordered', 'placeOrder'].includes(key))
                statusBackgroundColor = gray;

            orderStatusAlertText = currentStatusClass.alertText;
        }
    }

    return [statusBackgroundColor, orderStatusAlertText];
};
