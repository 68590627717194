import React, { ReactNode } from 'react';
import './slide.scss';
import { CarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import { useVehicleImage } from '@sections/account-portal/hooks/use-vehicle-image';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { CVOT_AUTHENTICATED_ORDER_STORAGE_KEY, NA_COUNTRIES } from '@constants';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useAnalytics } from '@hooks/use-analytics';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { NotificationType } from '@contexts/notificationContext';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import trashBin from '@assets/Trashbin.svg';

interface SlideProps {
    data: any;
    orderStatusAlertText?: string;
    hasCvotOrderNumber?: boolean;
    secondaryButtonLink?: string;
    onCTAClick?: () => void;
    booking?: CustomerBooking;
    children?: ReactNode;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    isOrderCard?: boolean;
    isLoading?: boolean;
    isReservation?: boolean;
    onCardRemove?: () => void;
    onModalClose?: () => void;
    showCta?: boolean;
}

interface DeleteVehicleButtonProps {
    isNA: boolean;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    fireAnalytics: (event: string) => void;
    data: any;
    setContext: (props: ModalContextProps) => void;
    deleteVehicleModalProps: ModalContextProps;
}

interface VehicleImageProps {
    vehicleImage: string;
    showCta: boolean;
    data: any;
}

interface OrderNumberAndVinBoxProps {
    hasStatusAndVin: boolean;
    isCVOTOrder: boolean;
    isNA: boolean;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    data: any;
}

interface OrderStatusBoxProps {
    isNA: boolean;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    orderStatusAlertText?: string;
}

interface SlidePrimaryButtonProps {
    isOrderCard: boolean;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    hasCvotOrderNumber: boolean;
    data: any;
    secondaryButtonLink: string;
    showCta: boolean;
    fireAnalytics: (event: string) => void;
    navigate: (path: string) => void;
    cvotOrEcomm: (link: string) => void;
}

const DeleteVehicleButton = ({
    isNA,
    slideContent,
    carouselContentFragment,
    fireAnalytics,
    data,
    setContext,
    deleteVehicleModalProps,
}: DeleteVehicleButtonProps) => (
    <button
        className={
            slideContent.vehicleSlidePrimaryCTALabelText ||
            carouselContentFragment.carddetailscta
                ? 'slide__secondary-button'
                : 'slide__secondary-button no-padding'
        }
        data-testid="delete-vehicle-button"
        aria-label={
            !isNA
                ? carouselContentFragment.vehicleCardDeleteButtonAriaLabel
                : slideContent.vehicleSlideRemoveVehicleButtonAriaLabel
        }
        onClick={() => {
            fireAnalytics('deleteTooltipOnclickEvent');
            sessionStorage.setItem('isEditId', data.description);
            setContext(deleteVehicleModalProps);
        }}
    >
        <span className="slide__secondary-button--icon">
            <img src={trashBin} alt="Trash bin icon" />
        </span>
    </button>
);

const VehicleImage = ({ vehicleImage, showCta, data }: VehicleImageProps) => {
    return vehicleImage ? (
        <div
            className={`slide__vehicle-image--container${
                showCta ? '' : '-no-cta'
            }`}
        >
            <img
                alt={`Image of a ${data.year} ${data.model}`}
                className="slide__vehicle-image"
                data-testid="vehicle-image"
                src={vehicleImage}
                crossOrigin="anonymous"
            />
        </div>
    ) : null;
};

const OrderNumberAndVinBox = ({
    hasStatusAndVin,
    isCVOTOrder,
    isNA,
    slideContent,
    carouselContentFragment,
    data,
}: OrderNumberAndVinBoxProps) => (
    <div
        className={`order-number-and-vin-box ${
            hasStatusAndVin ? 'has-both' : ''
        } ${isCVOTOrder ? 'four-digit-order-number' : ''}`}
    >
        {hasStatusAndVin && (
            <p data-testid="order-card-vin">
                {!isNA
                    ? carouselContentFragment.vinText
                    : slideContent?.vinLabelText}
                {': '}
                {data.vin}
            </p>
        )}
    </div>
);

const OrderStatusBox = ({
    isNA,
    slideContent,
    carouselContentFragment,
    orderStatusAlertText,
}: OrderStatusBoxProps) => {
    return (
        <div
            className="order-status-box"
            data-testid="ordered-vehicle-status-box"
        >
            <p>
                {!isNA
                    ? carouselContentFragment.eComOrderStatusBoxText
                    : slideContent?.orderStatusLabelText}
            </p>
            <p data-testid="order-vehicle-status-alert-text">
                {orderStatusAlertText}
            </p>
        </div>
    );
};

const SlidePrimaryButton = ({
    isOrderCard,
    slideContent,
    carouselContentFragment,
    hasCvotOrderNumber,
    data,
    secondaryButtonLink,
    showCta,
    fireAnalytics,
    navigate,
    cvotOrEcomm,
}: SlidePrimaryButtonProps) => (
    <div
        className={`slide__button-container${showCta ? '' : '-no-cta'}`}
        data-testid="slide-button-container"
    >
        {isOrderCard &&
        (slideContent.orderCardPrimaryCTALabelText ||
            carouselContentFragment.eComVehicleCardCtaLabel) ? (
            <>
                {hasCvotOrderNumber ? (
                    <button
                        className="slide__primary-button"
                        aria-label={data.ctaAriaLabel}
                        data-testid="order-slide-cvot-cta"
                        onClick={() => {
                            localStorage.setItem(
                                CVOT_AUTHENTICATED_ORDER_STORAGE_KEY,
                                JSON.stringify({
                                    customerOrderNumber: data.id,
                                    vin: data.vin,
                                })
                            );
                            fireAnalytics('vehicleOrderStatusOnclickEvent');
                            navigate(
                                findPathByAlias(
                                    'VehicleOrderTrackingStatusView'
                                )
                            );
                        }}
                    >
                        {slideContent.orderCardPrimaryCTALabelText ||
                            carouselContentFragment.eComVehicleCardCtaLabel}
                    </button>
                ) : (
                    <a
                        href={secondaryButtonLink}
                        target={data.ctaTarget}
                        className="slide__primary-button"
                        data-testid="order-slide-ecomm-cta"
                        onClick={() => {
                            cvotOrEcomm(secondaryButtonLink);
                        }}
                        aria-label={data.ctaAriaLabel}
                    >
                        {slideContent.orderCardPrimaryCTALabelText ||
                            carouselContentFragment.eComVehicleCardCtaLabel}
                    </a>
                )}
            </>
        ) : null}

        {!isOrderCard && carouselContentFragment.goToVehicleLabel ? (
            <div className={`go-to-vehicle-button${showCta ? '' : '-no-cta'}`}>
                <a
                    href={secondaryButtonLink}
                    target={data.ctaTarget}
                    className="slide__primary-button"
                    data-testid="go-to-vehicle-button"
                    aria-label={data.goToVehicleAriaLabel}
                    onClick={() => {
                        fireAnalytics('viewVehicleDetailsOnclickEvent');
                    }}
                >
                    {carouselContentFragment.goToVehicleLabel}
                </a>
            </div>
        ) : null}
    </div>
);

const Slide = ({
    data,
    children,
    slideContent,
    isOrderCard,
    carouselContentFragment,
    orderStatusAlertText,
    isReservation,
    onCardRemove,
    onModalClose,
    isLoading,
    secondaryButtonLink,
    booking,
    hasCvotOrderNumber,
    showCta,
}: SlideProps) => {
    const appConfig = new AppConfigurationService();
    const isNA: boolean = NA_COUNTRIES.includes(
        appConfig.get2LetterCountryCode()
    );
    const [fireAnalytics] = useAnalytics();
    const { setContext } = useModalContext();
    const navigate = useNavigate();
    const hasStatusAndVin: boolean = data?.status && data.vin;
    const isCVOTOrder: boolean = hasStatusAndVin && data.id.length === 4;
    const isLincoln = appConfig.brand === 'lincoln';
    const isFord = appConfig.brand === 'ford';

    const vinForVehicleImageLookup =
        isOrderCard && data.vin
            ? { vin: data.vin, year: data.year }
            : data?.vehicleAttributes;
    const vehicleImage = useVehicleImage(
        vinForVehicleImageLookup,
        data?.imageUrl,
        'undefined',
        appConfig.current3LetterCountryCapitalizedCode
    );
    const cvotOrEcomm = (secondaryButtonLink: string) => {
        secondaryButtonLink.includes('vehicleordertracking') ||
        secondaryButtonLink.includes('vehicle-order-tracking')
            ? fireAnalytics('vehicleOrderStatusOnclickEvent')
            : fireAnalytics('fvOrderDetailsOnclickEvent');
    };
    const gvtCtaHref: string = booking
        ? `${carouselContentFragment.gvtCTAHref + booking.bookingId}`
        : '';

    const deleteVehicleModalProps: ModalContextProps = {
        modalType: {
            name: 'delete-vehicle-modal',
            isVisible: true,
            onPrimaryButtonClick: () => onCardRemove(),
            onSecondaryButtonClick: () => onModalClose(),
            onAfterClose: () => onModalClose(),
            primaryButtonLabel: carouselContentFragment?.btnConfirm,
            primaryButtonAriaLabel:
                carouselContentFragment?.btnConfirmAriaLabel,
            secondaryButtonLabel: carouselContentFragment?.btnCancel,
            secondaryButtonAriaLabel:
                carouselContentFragment?.deleteVehicleBtnCancelAriaLabel,
            closeButtonAriaLabel:
                carouselContentFragment?.modalCloseButtonAriaLabel,
            children: (
                <>
                    <h2
                        className={`fmc-type--heading5 ${
                            isLincoln
                                ? 'fds-color__text--primary delete-vehicle-modal-header'
                                : ''
                        }`}
                    >
                        {carouselContentFragment?.deletevehiclemodalheader}
                    </h2>
                    {isFord && (
                        <p className="fmc-type--body1 delete-vehicle-modal-body1">
                            {carouselContentFragment.deleteVehicleModalBody}
                        </p>
                    )}
                </>
            ),
        },
    };
    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <div
                className={`slide__container ${
                    isOrderCard ? 'order-slide' : ''
                } ${!vehicleImage ? 'center-content-without-image' : ''}`}
                data-testid="slide-container"
            >
                <div className="slide__content-area">
                    {!isOrderCard && (
                        <DeleteVehicleButton
                            isNA={isNA}
                            slideContent={slideContent}
                            carouselContentFragment={carouselContentFragment}
                            fireAnalytics={fireAnalytics}
                            data={data}
                            setContext={setContext}
                            deleteVehicleModalProps={deleteVehicleModalProps}
                        />
                    )}
                    <div
                        className={`vehicle-container${
                            showCta ? '' : '-no-cta'
                        }`}
                    >
                        <div className="ymm-and-vin">
                            <h3 className="ymm">
                                {' '}
                                {isOrderCard
                                    ? `${data.year} ${data.model}`
                                    : data?.vehicleType ||
                                      `${data?.vehicleAttributes?.year} ${data?.vehicleAttributes?.model}`}
                            </h3>
                            {isOrderCard && (
                                <p className="order-and-reservation-number">
                                    {`${
                                        isReservation
                                            ? slideContent.reservationNumberLabelText ||
                                              carouselContentFragment.eComVehicleCardReservationNumberLabel
                                            : slideContent?.orderNumberLabelText ||
                                              carouselContentFragment.eComVehicleCardOrderNumberLabel
                                    }: ${data.id}`}
                                </p>
                            )}
                            {!isOrderCard && (
                                <div className="vin-and-mileage__container">
                                    <div className="vin__container">
                                        <span className="vin__label">
                                            {isNA
                                                ? slideContent.vinLabelText
                                                : carouselContentFragment.vinText}
                                            {': '}
                                        </span>
                                        <span className="vin__value">
                                            {data?.vin ||
                                                data?.vehicleAttributes?.vin}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <VehicleImage
                            vehicleImage={vehicleImage}
                            showCta={showCta}
                            data={data}
                        />
                    </div>
                    {!!booking && (
                        <div className="slide__notification-container">
                            <Notification
                                status={NotificationType.Info}
                                mainCopy={carouselContentFragment.gvtMainCopy}
                                subCopy={carouselContentFragment.gvtSubCopy}
                                ctaText={carouselContentFragment.gvtCTAText}
                                ctaHref={gvtCtaHref}
                                ctaAriaLabel={
                                    carouselContentFragment.gvtCTAAriaLabel
                                }
                                analyticsEvent={'gvtOnclickEvent'}
                            />
                        </div>
                    )}
                    <OrderNumberAndVinBox
                        hasStatusAndVin={hasStatusAndVin}
                        isCVOTOrder={isCVOTOrder}
                        isNA={isNA}
                        slideContent={slideContent}
                        carouselContentFragment={carouselContentFragment}
                        data={data}
                    />
                    {data?.status && (
                        <OrderStatusBox
                            isNA={isNA}
                            slideContent={slideContent}
                            carouselContentFragment={carouselContentFragment}
                            orderStatusAlertText={orderStatusAlertText}
                        />
                    )}

                    {children}

                    <SlidePrimaryButton
                        isOrderCard={isOrderCard}
                        slideContent={slideContent}
                        carouselContentFragment={carouselContentFragment}
                        hasCvotOrderNumber={hasCvotOrderNumber}
                        data={data}
                        secondaryButtonLink={secondaryButtonLink}
                        showCta={showCta}
                        fireAnalytics={fireAnalytics}
                        navigate={navigate}
                        cvotOrEcomm={cvotOrEcomm}
                    />
                </div>
            </div>
        </>
    );
};

export default Slide;
