import React, { useContext, useEffect, useState } from 'react';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { HeaderFragment } from '@models/experiencefragments/header';
import { FooterFragment } from '@models/experiencefragments/footer';
import { useExperienceContent } from '@hooks/use-server-content';
import ServerContext from '@contexts/serverContext';
import { Link } from '@common/index';
import { DisclosuresLink } from '../disclosures-link/disclosures-link';
import './footer.scss';
import { ServerSideService } from '@services/server-side-service/server-side-service';

export const Footer = () => {
    const serverContext = useContext(ServerContext);
    const serverSideService = new ServerSideService();
    const hasWindow = serverSideService.isClientSide();
    const [footerContent] = useExperienceContent<FooterFragment>(
        'common',
        'header-footer',
        'footer'
    );

    const appConfig = new AppConfigurationService();
    const currentRegionCode = appConfig.currentRegionCode;
    const [country] = [
        serverContext ? serverContext.currentRegionCode : currentRegionCode,
    ];

    const [headerContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    const [canLoadFeedbackForm, setCanLoadFeedbackForm] =
        useState<boolean>(false);
    const [feedbackIcon, setFeedbackIcon] = useState<string>(
        './icons/feedback.svg'
    );

    const MEDALLIA_CONST = 'KAMPYLE_ONSITE_SDK';

    const medalliaCommentCardId =
        appConfig.getAppConfiguration().medalliaCommentCardId;

    const tertiaryLinks = footerContent?.tertiarySection?.tertiaryLinks?.filter(
        (link) => !link?.url?.includes(MEDALLIA_CONST)
    );

    const medalliaLinks = footerContent?.tertiarySection?.tertiaryLinks?.filter(
        (link) => link?.url?.includes(MEDALLIA_CONST)
    );

    useEffect(() => {
        if (process.env.PUBLIC_URL)
            setFeedbackIcon(process.env.PUBLIC_URL + '/icons/feedback.svg');
    }, [process.env.PUBLIC_URL]);

    useEffect(() => {
        if (hasWindow) {
            const win = window as any;
            if (win.KAMPYLE_ONSITE_SDK) {
                setCanLoadFeedbackForm(
                    win.KAMPYLE_ONSITE_SDK.loadForm(medalliaCommentCardId)
                );
            } else {
                window.addEventListener('neb_OnsiteLoaded', () => {
                    setCanLoadFeedbackForm(
                        win.KAMPYLE_ONSITE_SDK.loadForm(medalliaCommentCardId)
                    );
                });
            }
        }
    }, [hasWindow]);

    return (
        (footerContent?.primarySection?.primaryLinks?.length > 0 &&
            headerContent && (
                <footer className="footer">
                    <DisclosuresLink />
                    <div className="primary-section pipe-separated-list-container">
                        <ul className="skinny">
                            {footerContent.primarySection &&
                                footerContent.primarySection.primaryLinks.map(
                                    (link, index) => {
                                        const maplength =
                                            footerContent.primarySection
                                                .primaryLinks.length;
                                        return (
                                            <li key={index}>
                                                <span
                                                    className={`${
                                                        index === 0
                                                            ? 'primary-first-item'
                                                            : ''
                                                    }
                                        ${
                                            index === maplength - 1
                                                ? 'primary-last-item'
                                                : 'content-label'
                                        }`}
                                                ></span>
                                                <Link
                                                    key={index}
                                                    href={link.url}
                                                    className={`${
                                                        index === maplength - 1
                                                            ? 'sec-link-last'
                                                            : 'sec-link-color'
                                                    }`}
                                                    target={
                                                        link.targetBlank
                                                            ? '_blank'
                                                            : '_self'
                                                    }
                                                >
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    <div className="tertiary-first-title">
                        {footerContent?.copyrightText}
                    </div>
                    <div className="tertiary-section links">
                        {footerContent.tertiarySection &&
                            footerContent.tertiarySection.tertiaryLinks.map(
                                (link, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            href={link.url}
                                            className={`${
                                                link?.url?.indexOf(
                                                    'cookieSettings'
                                                ) > 0
                                                    ? 'optanon-toggle-display link'
                                                    : 'link'
                                            }`}
                                            target={
                                                link.targetBlank
                                                    ? '_blank'
                                                    : '_self'
                                            }
                                        >
                                            {link.title}
                                        </Link>
                                    );
                                }
                            )}
                        <a
                            tabIndex={0}
                            href={headerContent.logo.url}
                            target={
                                headerContent.logo.targetBlank
                                    ? '_blank'
                                    : '_self'
                            }
                            className="logo-link"
                            aria-label={headerContent.logo.ariaLabel}
                            rel="noopener noreferrer"
                        >
                            <span
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${headerContent.logo.logoPath})`,
                                }}
                                className={'ford-logo'}
                            />
                        </a>
                    </div>
                </footer>
            )) || (
            <footer className="eu-footer">
                <DisclosuresLink />
                <div className="eu-footer-nav-container">
                    {footerContent?.['footer-nav-category']?.length > 0 && (
                        <div className="eu-footer-nav">
                            {footerContent?.['footer-nav-category']?.map(
                                (nav, index) => {
                                    return (
                                        <div
                                            className="footer-categories-container"
                                            key={index}
                                        >
                                            {nav?.categories &&
                                                nav?.categories?.map(
                                                    (categories) => {
                                                        return (
                                                            <ul
                                                                className="footer-categories"
                                                                key={index}
                                                            >
                                                                <li
                                                                    key={index}
                                                                    className="footer-sub-nav-title-container"
                                                                >
                                                                    <h3 className="footer-sub-nav-title">
                                                                        {
                                                                            categories?.categoryTitle
                                                                        }
                                                                    </h3>
                                                                    <ul className="footer-nav-categories">
                                                                        {categories?.categoryItems &&
                                                                            categories?.categoryItems?.map(
                                                                                (
                                                                                    items
                                                                                ) => {
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className="footer-nav-link"
                                                                                        >
                                                                                            <Link
                                                                                                target={
                                                                                                    items?.targetBlank
                                                                                                        ? '_blank'
                                                                                                        : ''
                                                                                                }
                                                                                                href={
                                                                                                    items?.url
                                                                                                }
                                                                                                aria-label={
                                                                                                    items?.ariaLabel
                                                                                                }
                                                                                            >
                                                                                                <span className="categories-items-title">
                                                                                                    {
                                                                                                        items?.title
                                                                                                    }
                                                                                                </span>
                                                                                            </Link>
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="eu-footer-social-icons"
                    data-testid="eu-footer-social-icons"
                >
                    {footerContent?.socialSections?.socialLinks.length > 0 &&
                        footerContent?.socialSections?.socialLinks?.map(
                            (socialLinks, index) => {
                                return (
                                    <div className="social-icons" key={index}>
                                        <a href={socialLinks?.url}>
                                            <img
                                                key={index}
                                                src={
                                                    process.env
                                                        .REACT_APP_AEM_BASE_URL +
                                                    socialLinks?.logoPath
                                                }
                                                alt={socialLinks?.title}
                                            />
                                        </a>
                                    </div>
                                );
                            }
                        )}
                </div>
                {canLoadFeedbackForm && (
                    <div
                        className="eu-footer-secondary-links-container"
                        data-testid="eu-footer-secondary-links-container"
                    >
                        <div className="eu-footer-secondary-links">
                            <ul className="eu-secondary-links-list">
                                {medalliaLinks?.map((link) => (
                                    <>
                                        <li key={link.title}>
                                            <Link
                                                href={undefined}
                                                className="eu-tertiary-link"
                                                aria-label={link.ariaLabel}
                                                target={
                                                    link.targetBlank
                                                        ? '_blank'
                                                        : '_self'
                                                }
                                                onClick={() => {
                                                    const win2 = window as any;
                                                    win2.KAMPYLE_ONSITE_SDK.showForm(
                                                        medalliaCommentCardId
                                                    );
                                                }}
                                            >
                                                <span className="icon">
                                                    <img
                                                        src={feedbackIcon}
                                                        alt=""
                                                    />
                                                </span>
                                                <span className="text">
                                                    {link.title}
                                                </span>
                                            </Link>
                                        </li>
                                    </>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <div className="footer-tertiary-logo-container">
                    <div
                        className="tertiary-links-container"
                        data-testid="tertiary-links-container"
                    >
                        <ul className="tertiary-text">
                            <li>
                                {footerContent &&
                                    footerContent?.copyrightText && (
                                        <span>
                                            {' '}
                                            {footerContent?.copyrightText}
                                        </span>
                                    )}
                            </li>
                            {footerContent &&
                                tertiaryLinks.map((tertiaryLinks, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={tertiaryLinks.url}>
                                                {tertiaryLinks.title}
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                    <div
                        className="ford-logo-container"
                        data-testid="ford-logo-container"
                    >
                        {footerContent &&
                            footerContent?.logo?.logoLinks?.length > 0 &&
                            footerContent?.logo?.logoLinks?.map(
                                (logoLinks, index) => {
                                    return (
                                        <div className="ford-logo" key={index}>
                                            <a href={logoLinks.url}>
                                                <img
                                                    key={index}
                                                    src={
                                                        process.env
                                                            .REACT_APP_AEM_BASE_URL +
                                                        logoLinks?.logoPath
                                                    }
                                                    alt={logoLinks?.title}
                                                />
                                            </a>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                </div>
            </footer>
        )
    );
};
